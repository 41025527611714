<template>
  <div>
    <input
      type='password'
      v-model='inputValue'
      :placeholder='placeholder'
      class='border py-1 px-2 rounded block w-full'>
  </div>
</template>

<script>
export default {
  name: 'EditFormFieldPassword',
  props: [
    'metaData',
  ],
  data () {
    return {
      inputValue: '',
    }
  },
  watch: {
    inputValue: {
      handler: function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.$emit('update:inputValue', newVal)
        }
      }, 
      immediate: true
    },
  },
  computed: {
    placeholder () {
      return this.metaData && this.metaData.placeholder ? this.metaData.placeholder : ''
    },
  }
}
</script>
